<template>
  <Layout>
    <div class="mb-4">
      <label for="" class="text-xs mb-2 uppercase text-gray-400 font-medium"
        >Email</label
      >
      <input
        type="email"
        class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
        placeholder="Enter email"
        v-model="form.email"
      />
    </div>
    <button
      @click="handleResetPassword"
      :disabled="validateInput"
      class="bg-orange-600 py-4 w-full block text-center rounded-md disabled:opacity-60 text-white text-sm my-3"
    >
      {{ loading ? "Please wait..." : "Reset password" }}
    </button>
    <p class="text-sm text-gray-500 text-center">
      Back to
      <router-link to="/login" class="text-blue-500 cursor-pointer">
        login
      </router-link>
    </p>
  </Layout>
</template>
<script>
import Layout from "./Layout.vue";
import { defineComponent, ref, computed } from "vue";
import { forgotPassword } from "@/services";

export default defineComponent({
  name: "ResetPasswordView",
  components: { Layout },
  setup() {
    const loading = ref(false);
    const form = ref({
      email: "",
    });

    async function handleResetPassword() {
      loading.value = true;
      try {
        const res = await forgotPassword(form.value);

        this.$toast.success(res.message);
      } catch (err) {
        this.$toast.error(err?.response?.data?.message || "Network Error");
      }
      loading.value = false;
    }
    return {
      form,
      handleResetPassword,
      validateInput: computed(() => !form.value.email),
      loading,
    };
  },
});
</script>
