import auth from "@/router/middleware/auth";

export const protectedRoutes = [
  {
    path: "/",
    name: "Layout",
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/UI/Layouts/DefaultLayout.vue"
      ),
    meta: { middleware: [auth] },
    children: [
      {
        path: "",
        name: "DashboardView",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/protected/home/Index.vue"
          ),
      },
      {
        path: "/partners",
        name: "partnersView",
        component: () =>
          import(
            /* webpackChunkName: "partner" */ "@/views/protected/partners/Index.vue"
          ),
      },
      {
        path: "/pay-in",
        name: "payinView",
        component: () =>
          import(
            /* webpackChunkName: "payinView" */ "@/views/protected/payin/index.vue"
          ),
      },
      {
        path: "/partner-transaction/:id",
        name: "partner-transaction",
        component: () =>
          import(
            /* webpackChunkName: "partner-transaction" */ "@/views/protected/partners/PartnerTransactions.vue"
          ),
      },
      {
        path: "/nip-inward",
        name: "NipInwardTransaction",
        component: () =>
          import(
            /* webpackChunkName: "NipInwardTransaction" */ "@/views/protected/pendingInward/Index.vue"
          ),
      },
      {
        path: "/partners/:id/customers",
        name: "partnerCustomersView",
        component: () =>
          import(
            /* webpackChunkName: "partner customers" */ "@/views/protected/partners/PartnerCustomers.vue"
          ),
      },
      {
        path: "/partners/:id/users",
        name: "PartnerUsersView",
        component: () =>
          import(
            /* webpackChunkName: "partner customers" */ "@/views/protected/partners/PartnerUsers.vue"
          ),
      },
      {
        path: "/business-request",
        name: "BusinessView",
        component: () =>
          import(
            /* webpackChunkName: "business-view" */ "@/views/protected/business/Index.vue"
          ),
      },
      {
        path: "/transaction",
        name: "TransactionView",
        component: () =>
          import(
            /* webpackChunkName: "transaction" */ "@/views/protected/transaction/Index.vue"
          ),
      },
      {
        path: "/swiss-transaction",
        name: "SwissTransactionView",
        component: () =>
          import(
            /* webpackChunkName: "SwissPayout" */ "@/views/protected/transaction/SwissPayout.vue"
          ),
      },
      {
        path: "/users",
        name: "UsersView",
        component: () =>
          import(
            /* webpackChunkName: "users" */ "@/views/protected/users/Index.vue"
          ),
      },
      {
        path: "/dispute",
        name: "DisputeView",
        component: () =>
          import(
            /* webpackChunkName: "disputes" */ "@/views/protected/disputes/Index.vue"
          ),
      },
      {
        path: "/dispute/view-dispute/:id",
        name: "AllDisputeView",
        component: () =>
          import(
            /* webpackChunkName: "view-disputes" */ "@/views/protected/disputes/ViewDisputes.vue"
          ),
      },
      {
        path: "/dispute/view-dispute/:id/show/:messageId",
        name: "SingleDisputeView",
        component: () =>
          import(
            /* webpackChunkName: "view-disputes" */ "@/views/protected/disputes/SingleDispute.vue"
          ),
      },
      {
        path: "/payouts",
        name: "PayoutView",
        component: () =>
          import(
            /* webpackChunkName: "payouts" */ "@/views/protected/payouts/Index.vue"
          ),
      },
      {
        path: "/settings",
        name: "SettingsView",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/protected/settings/Index.vue"
          ),
      },

      // {
      //   path: "/settings",
      //   name: "SettingsView",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "settings" */ "@/views/protected/settings/Account.vue"
      //     ),
      // },

      {
        path: "/all-admin",
        name: "AllAdminView",
        component: () =>
          import(
            /* webpackChunkName: "all-admin" */ "@/views/protected/admin/Index.vue"
          ),
      },
      {
        path: "/notification",
        name: "NotificationView",
        component: () =>
          import(
            /* webpackChunkName: "all-admin" */ "@/views/protected/notification/Index.vue"
          ),
      },
      {
        path: "/check-status",
        name: "CheckTransactionStatus",
        component: () =>
          import(
            /* webpackChunkName: "all-admin" */ "@/views/protected/transactionStatus/Index.vue"
          ),
      },
      {
        path: "/charges/:id",
        name: "PartnerCharges",
        component: () =>
          import(
            /* webpackChunkName: "all-admin" */ "@/views/protected/charges/Index.vue"
          ),
        props: true,
      },

      // {
      //   path: "/settings",
      //   name: "SettingsView",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "settings" */ "@/views/protected/settings/Account.vue"
      //     ),
      // },
    ],
  },
];
