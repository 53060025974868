/* eslint-disable prettier/prettier */
import axios from "axios";
/* eslint-disable */
import { encrypted, decrypted } from "@/helpers/algo";
// eslint-disable
window.axios = axios;
import { clearStorage } from "@/helpers/auth";
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.interceptors.response.use((response) => response.data);
//Default setup for Authorization
const token = JSON.parse(localStorage.getItem("KB_token"));

const options = {
     baseURL: process.env.VUE_APP_API_BASEURL,
     headers: {
          Accept: "application/json,text/plain,*/*",
          "Content-Type": "multipart/form-data",
          "x-api-key": process.env.VUE_APP_API_KEY,
          Authorization: `Bearer ${token}`,
     },
};

export const formData = axios.create(options);

formData.interceptors.request.use(
     (request) => {
          // console.log(request.data);
          // if (
          //      request.method === "post"
          // ) {
          //      request.data = { data: encrypted(request.data) };
          // }
          return request;
     },
     (error) => {
          return Promise.reject(error);
     }
);
// eslint-disable
formData.interceptors.response.use(
     (response) => {
          return response;
     },
     (error) => {
          try {
               // eslint-disable
               const errorData = error?.response?.data;
               if (errorData?.message === "Unauthenticated.") {
                    clearStorage();
               }
               if (errorData?.status === 403) {
                    clearStorage();
               }

               return Promise.reject({
                    response: {
                         data: { ...decrypted(errorData?.data) ?? "" },
                    },
               });
          } catch (error) {
               return Promise.reject(error);
          }
     }
);
