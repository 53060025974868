import { request } from "../../utils";

export const getUsers = async (page) => {
  const response = await request.get(`/users?page=${page}`);
  return response.data;
};
export const getFilterUsers = async ({ page = 1, keyword }) => {
  const response = await request.get(`/users?page=${page}&keyword=${keyword}`);
  return response.data;
};

export const getSingleUser = async (user_id) => {
  const response = await request.get(`/users?id=${user_id}`);
  return response.data;
};

export const getUserPartner = async (user_id) => {
  const response = await request.get(`/users/${user_id}/partners`);
  return response.data;
};

export const getUserSettings = async (user_id) => {
  const response = await request.get(`/user-settings/${user_id}`);
  return response.data;
};
export const getImpersonateUser = async (user_id) => {
  const response = await request.get(`/users/${user_id}/impersonate`);
  return response.data;
};

export const updateUserSettings = async (payload) => {
  const response = await request.post(`/change-user-settings`, payload);
  return response;
};

export const createCharges = async (payload) => {
  const response = await request.post(`/change-user-settings`, payload);
  return response;
};
