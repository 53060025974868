<template>
  <div class="bg h-screen flex flex-wrap items-center">
    <div class="w-full mx-4 md:mx-auto mb-4 py-8" :class="width">
      <div class="flex items-center justify-center mb-4">
        <img
          alt="Kredi logo"
          src="@/assets/kredi.png"
          class="w-32 object-cover"
        />
      </div>
      <div class="bg-white shadow-md rounded-md px-8 py-16">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutView",
  props: {
    width: {
      type: String,
      default: " md:w-3/12",
    },
  },
};
</script>

<style>
.bg {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: radial-gradient(#444cf7 0.5px, transparent 0.5px),
    radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}
</style>
