import { createStore } from "vuex";
import auth from "./modules/auth";
import admin from "./modules/admin/admin";
import disputes from "./modules/disputes/index";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    admin,
    disputes,
  },
});
