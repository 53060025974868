import { createApp } from "vue";
import "remixicon/fonts/remixicon.css";
import VOtpInput from "vue3-otp-input";
import DataTable from "datatables.net-vue3";
import DataTablesLib from "datatables.net";
import Toaster from "@meforma/vue-toaster";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

DataTable.use(DataTablesLib);
createApp(App)
  .use(store)
  .use(router)
  .use(Toaster, {
    position: "top-right",
  })
  .component("v-otp-input", VOtpInput)
  .component("v-select", vSelect)
  .mount("#app");
