import CryptoJS from "crypto-js";

var CryptoJSAesJson = {
  stringify: function (cipherParams) {
    var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    if (cipherParams.iv) j.iv = cipherParams.iv.toString();
    if (cipherParams.salt) j.s = cipherParams.salt.toString();
    return JSON.stringify(j);
  },
  parse: function (jsonStr) {
    var j = JSON.parse(jsonStr);
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.ct),
    });
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
    return cipherParams;
  },
};

export const encrypted = (data) => {
  var encrypt = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.VUE_APP_ENCRYPTION_KEY,
    {
      format: CryptoJSAesJson,
    }
  ).toString();

  return window.btoa(encrypt);
};

export const decrypted = (encrypted) => {
  var decodeBase64 = window.atob(encrypted);
  return JSON.parse(
    CryptoJS.AES.decrypt(decodeBase64, process.env.VUE_APP_ENCRYPTION_KEY, {
      format: CryptoJSAesJson,
    }).toString(CryptoJS.enc.Utf8)
  );
};
