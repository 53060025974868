import { request, formData } from "../../utils";
/* eslint-disable */

export const getBusinessRequest = async ({ page }) => {
  const response = await request.get(`/business/requests?page=${page}`);
  return response.data;
};

export const getShowBusinessRequest = async (id) => {
  const response = await request.get(`/business/requests?id=${id}`);
  return response.data.data;
};

export const createPartner = async (payload) => {
  const response = await request.post(`/partners/create-existing`, payload);
  return response.data;
};
export const updateBusiness = async ({ businessId, payload }) => {
  // console.log(payload);
  const response = await formData.post(`/partners/${businessId}/update-partner`, payload);
  return response.data;
};

export const findExistingPartner = async (payload) => {
  const response = await request.post(`/partners/find-existing`, payload);
  return response.data;
};
