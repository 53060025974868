import { request, swissRequest } from "../../utils";
/* eslint-disable */

export const getTransactions = async ({ type = "outward", page }) => {
  const response = await request.get(`/transactions/${type}?page=${page}`);
  return response.data;
};

export const getFilteredTransactions = async ({ type = "outward", page, from, to, keyword }) => {
  const response = await request.get(`/transactions/date-range/${type}?page=${page}&from=${from}&to=${to}&keyword=${keyword}`);
  return response.data;
};

export const updateTransactions = async (values) => {
  const response = await request.post(`/transactions/update`, values);
  return response.data;
};
export const getnipInwardTransactions = async ({ page, values }) => {
  const response = await request.get(`/view-nip?page=${page}${values}`);
  return response.data;
};
export const regulatenipInwardTransactions = async ({ values }) => {
  const response = await request.post(`/regulate-payin`, values);
  return response.data;
};

export const findTransaction = async (value) => {
  const response = await request.get(`/transactions/collections/validate/${value}`);
  return response;
};

export const getBanks = async () => {
  const response = await request.get(`/transactions/banks`);
  return response.data;
};

export const processTransactionReference = async (payload) => {
  const response = await request.post(`/transactions/collections/process`, payload);
  return response.data;
};

export const getNewPayinTransactions = async ({
  page = 1,
  values,
}) => {
  const response = await request.get(
    `/transactions/payin?page=${page}&${values}`
  );
  return response.data;
};
export const getNewPayinTransactionsDownload = async ({
  values,
}) => {
  const response = await request.get(
    `/transactions/payin?${values}`
  );
  return response.data;
};

export const sendFinanceOtp = async () => {
  const response = await request.get(`/send-otp?finance=yes`);
  return response;
};

export const checkOTP = async (otp) => {
  const response = await request.post(`/check-otp`, {otp});
  return response;
};

export const getSwissTransactions = async ({page = 1, partner_nuban}) => {
  const response = await swissRequest.get(`/transactions?partner_nuban=${partner_nuban}&page=${page}&per_page=40`);
  return response.data;
};
export const getSwissTransactionsKeyword = async ({ page = 1, keyword, partner_nuban }) => {
  const response = await swissRequest.get(
    `/view-transactions?partner_nuban=${partner_nuban}&pagepage=${page}&keyword=${keyword}`
  );
  return response.data;
};
export const getSwissTransactionsFilter = async ({
  page = 1,
  values = "",
  amount = "",
  status = "",
  partner_nuban
}) => {
  const response = await swissRequest.get(
    `/view-transactions?partner_nuban=${partner_nuban}&page=${page}&transaction_amount=${amount}&transaction_status=${status}&${values}`
  );
  return response.data;
};