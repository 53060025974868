import axios from "axios";
window.axios = axios;
import { clearStorage } from "@/helpers/auth";
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.interceptors.response.use((response) => response.data);

const token = JSON.parse(localStorage.getItem("KB_token"));

const options = {
  baseURL: process.env.VUE_APP_SWISS_API,
  headers: {
    Accept: "application/json,text/plain,*/*",
    "Content-Type": "application/json",
    "Api-Key": process.env.VUE_APP_SWISS_API_KEY,
    Authorization: `Bearer ${token}`,
  },
};

export const swissRequest = axios.create(options);

swissRequest.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// eslint-disable
swissRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      // eslint-disable
      const errorData = error?.response?.data;
      if (errorData?.message === "Unauthenticated.") {
        clearStorage();
      }
      if (errorData?.status === 403) {
        clearStorage();
      }

      return Promise.reject({
        response: {
          data: errorData,
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
