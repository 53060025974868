/* eslint-disable */
import { request } from "../../utils";
// import { decrypted } from "@/helpers/algo";
export const registerAdmin = async (values) => {
  const response = await request.post(`/register`, values);
  return response.data;
};

export const getAllAdmins = async ({ page }) => {
  const response = await request.get(`/users/admins?page=${page}`);
  return response.data;
};

export const removeAdmin = async (id, values) => {
  const response = await request.patch(`/users/${id}/remove-admin`, values);
  return response.data;
};
