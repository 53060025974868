import { request } from "../../utils";

export const summary = async () => {
  const response = await request.get(`/summary`);
  return response.data;
};
export const getSummaryOutwardTransactions = async () => {
  const response = await request.get(`/transactions/outward`);
  return response.data;
};
export const getSummaryInwardTransactions = async () => {
  const response = await request.get(`/transactions/inward`);
  return response.data;
};
