// import { decrypted } from "@/helpers/algo";
import { request } from "../../utils";
/* eslint-disable */

export const getNotifications = async () => {
  const response = await request.get(`/notifications`);
  return response.data;
};
export const getSingleNotification = async (notificationId) => {
  const response = await request.get(`/notifications/view/${notificationId}`);
  return response.data;
};
export const postNotifications = async (postValues) => {
  const res = await request.post(`/notifications/create`, postValues);
  return res;
};
export const updateNotifications = async (id, postValues) => {
  const res = await request.post(`/notifications/edit/${id}`, postValues);
  return res;
};

export const deleteNotifications = async (id, postValues) => {
  const res = await request.delete(`/notifications/delete/${id}`, postValues);
  return res;
};
