// import { decrypted } from "@/helpers/algo";
import { request } from "../../utils";

export const login = async (values) => {
  const response = await request.post(`/login`, values);
  return response;
};

export const verifyOtp = async (values) => {
  const response = await request.post(`/verify-otp`, values);
  return response;
};

export const sendLogout = async () => {
  const response = await request.post(`/logout`);
  return response;
};

export const forgotPassword = async ({ email }) => {
  const response = await request.post(`/forgot-password`, { email });
  return response;
};

export const resetPassword = async ({
  reset_token,
  uuid,
  password,
  password_confirmation,
}) => {
  const response = await request.post(`/reset-password`, {
    reset_token,
    uuid,
    password,
    password_confirmation,
  });
  return response;
};
