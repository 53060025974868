const state = {
  token: JSON.parse(localStorage.getItem("KB_token")) || "",
  form: {},
};

const getters = {};

const mutations = {};

const actions = {};

export default {
  namedspaced: true,
  state,
  getters,
  mutations,
  actions,
};
