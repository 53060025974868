import axios from "axios";
import { encrypted, decrypted } from "@/helpers/algo";
import { clearStorage } from "@/helpers/auth";

window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.interceptors.response.use((response) => response.data);
//Default setup for Authorization
const token = JSON.parse(localStorage.getItem("KB_token"));

const options = {
  baseURL: process.env.VUE_APP_API_BASEURL,
  headers: {
    Accept: "application/json,text/plain,*/*",
    "Content-Type": "application/json",
    "x-api-key": process.env.VUE_APP_API_KEY,
    Authorization: `Bearer ${token}`,
  },
};

export const request = axios.create(options);

request.interceptors.request.use(
  (request) => {
    // console.log(request.url);
    if (request.method === "post" || request.data) {
      request.data = request.data
        ? { data: encrypted(request.data) }
        : undefined;
    }
    if (request.method === "get") {
      request.data = { data: encrypted(request.data) };
    }
    return request;
  }
  // (error) => {}
);

request.interceptors.response.use(
  (response) => {
    const { data } = response.data;
    return decrypted(data);
  },
  (error) => {
    try {
      // console.log(error?.response.data);
      const errorData = error?.response?.data;

      if (errorData?.message === "Unauthenticated.") {
        clearStorage();
      }

      return Promise.reject({
        response: { data: { ...decrypted(errorData.data) } },
      });
    } catch (error) {
      return Promise.reject(decrypted(error));
    }
  }
);
