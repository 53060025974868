<template>
  <div class="bg-gray-100 h-screen flex items-center justify-center">
    <div class="w-1/2 mx-auto text-center">
      <div class="flex items-center justify-center text-[50px] font-bold">
        <i class="ri-number-4"></i>
        <i class="ri-number-0"></i>
        <i class="ri-number-4"></i>
      </div>
      <p class="mb-6">
        Uh oh. It looks like you've somehow managed to arrive at a terminal with
        no rails leading here. Please click the button below to go back to the
        homepage.
      </p>
      <button
        @click="$router.go(-1)"
        class="bg-orange-500 py-4 px-10 rounded-md"
      >
        Take the train home
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
