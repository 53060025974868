import { request } from "../../utils";

export const getPartners = async (page = 1) => {
  const response = await request.get(`/partners?page=${page}`);
  return response.data;
};

export const getSinglePartner = async (partner_id) => {
  const response = await request.get(`/partners/${partner_id}`);
  return response.data;
};

export const searchPartners = async (search) => {
  const response = await request.get(
    `/partners/search-partner?search=${search}`
  );
  return response.data;
};

export const getPartnerCustomers = async (id) => {
  const response = await request.get(`/partners/${id}/customers`);
  return response.data;
};

export const getPartnerUsers = async (id) => {
  const response = await request.get(`/partners/${id}/users`);
  return response.data;
};

export const getSinglePartnerCustomer = async (id, customerId) => {
  const response = await request.get(`/partners/${id}/customers/${customerId}`);
  return response.data;
};
export const getPartnerAccountBalance = async (partner_id) => {
  const response = await request.get(`/partners/${partner_id}/account-balance`);
  return response.data;
};
export const getPartnerCustomersAccountBalance = async (
  partner_id,
  customerId
) => {
  const response = await request.get(
    `/partners/${partner_id}/customers/${customerId}/account-balance`
  );
  return response.data;
};
// export const approvePartner = async ({
//   user_email,
//   organisation_name,
//   phone_no,
//   email,
//   country,
//   address,
//   rc_number,
//   type_of_business,
//   date_of_incorporation,
//   cac_document,
// }) => {
//   const response = await request.post(`/partners/create`, {
//     user_email,
//     organisation_name,
//     phone_no,
//     email,
//     country,
//     address,
//     rc_number,
//     type_of_business,
//     date_of_incorporation,
//     cac_document,
//   });
//   return response.data;
// };

export const approvePartner = async ({ id }) => {
  const response = await request.post(`/business/${id}/approve`);
  return response;
};

export const declinePartner = async (payload) => {
  const response = await request.post(
    `/business/${payload.id}/decline`,
    payload.body
  );
  return response;
};

export const addCharges = async (id, charges) => {
  const response = await request.post(`/partners/${id}/charges`, charges);
  return response;
};

export const getCharges = async (id) => {
  const response = await request.get(`/partners/${id}/charges`);
  return response;
};

export const updateCharges = async (payload) => {
  const response = await request.post(`partners/charges/update`, payload);
  return response;
};

export const deleteCharges = async (id) => {
  const response = await request.delete(`/partners/charges/delete/${id}`);
  return response;
};
