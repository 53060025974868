import Login from "@/views/public/auth/Login.vue";
import TwoFactorAuth from "@/views/public/auth/2Factor.vue";
import guest from "@/router/middleware/guest";
import Register from "@/views/public/auth/Register.vue";
import ResetPassword from "@/views/public/auth/ResetPassword.vue";
import ChangePassword from "@/views/public/auth/ChangePassword.vue";

export const publicRoutes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { middleware: [guest] },
  },
  {
    path: "/2factor-auth",
    name: "2Factor",
    component: TwoFactorAuth,
    meta: { middleware: [guest] },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { middleware: [guest] },
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: { middleware: [guest] },
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: ChangePassword,
    meta: { middleware: [guest] },
  },
];
// changepassword?tk=SyWR5PJr&i=0a71ca65-98fa-4ebf-bd73-fb0d7c18e8e6
