import { createRouter, createWebHistory } from "vue-router";

import { protectedRoutes } from "@/views/protected/router";
import { publicRoutes } from "@/views/public/router";
import middlewarePipeline from "./kernel/middlewarePipeline";
import store from "../store";
import NotFound from "@/components/NotFound/Index.vue";

const routes = [
  ...protectedRoutes,
  ...publicRoutes,
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
