import { request } from "../../utils";

export const updateAllSettings = async (payload) => {
  const response = await request.post(`/change-settings`, payload);
  return response;
};

export const updatePassword = async (payload) => {
  const response = await request.post(`/change-password`, payload);
  return response;
};

export const sendOTP = async () => {
  const response = await request.post(`/send-otp`);
  return response;
};
