<template>
  <Layout width="md:w-4/12">
    <h4 class="font-medium text-lg">Confirm phone number</h4>
    <p class="text-sm text-gray-600 mb-4">
      Please enter the code sent to the email
    </p>
    <div class="my-4 flex justify-center">
      <v-otp-input
        ref="otpInput"
        input-classes="otp-input"
        separator="-"
        :num-inputs="6"
        :should-auto-focus="true"
        :is-input-num="true"
        :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
        :placeholder="['o', 'o', 'o', 'o', 'o', 'o']"
        @on-change="handleOnChange"
      />
    </div>

    <p class="text-xs text-blue-500 mb-8 text-center">
      Didn’t recieve a code?
      <span
        @click="handleResendOTP"
        class="bg-green-600 text-white font-medium p-1 cursor-pointer"
        >Resend</span
      >
    </p>
    <button
      :disabled="validateInput"
      class="bg-orange-600 py-4 w-full block text-center disabled:opacity-60 rounded-md text-white text-sm my-3"
      @click="verifyEmail"
    >
      {{ loading ? "Please wait..." : "Continue" }}
    </button>
    <p class="text-sm text-gray-500 text-center">
      <router-link to="/login" class="text-blue-500 cursor-pointer">
        Back to login
      </router-link>
    </p>
  </Layout>
</template>

<script>
import Layout from "./Layout.vue";
import { defineComponent, ref, computed, onMounted } from "vue";
import { verifyOtp, login } from "@/services";
import { useStore } from "vuex";

export default defineComponent({
  name: "2Factor",
  components: { Layout },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const form = ref({
      email: "",
      otp: "",
    });

    const handleOnChange = (e) => {
      form.value.otp = e;
    };

    async function handleResendOTP() {
      loading.value = true;
      try {
        await login(store.state.auth.form);
        this.$toast.success("OTP sent");
      } catch (err) {
        this.$toast.error(err.response.data?.message || "Network Error");
      }
      loading.value = false;
    }

    async function verifyEmail() {
      loading.value = true;
      try {
        const res = await verifyOtp({
          email: store.state.auth.form.email,
          otp: form.value.otp,
        });
        form.value.otp = "";
        store.commit("SAVE_USER", res.data);
        this.$toast.success(res?.message);
        window.location.href = "/";
      } catch (err) {
        this.$toast.error(err.response.data?.message || "Network Error");
      }
      loading.value = false;
    }

    const handleRedirect = () => {
      if (!store.state.auth.form.email) window.location.href = "/";
    };

    onMounted(() => {
      handleRedirect();
    });
    return {
      form,
      handleOnChange,
      verifyEmail,
      loading,
      validateInput: computed(() => form.value.otp.length < 6),
      handleResendOTP,
    };
  },
});
</script>

<style>
.one,
.two,
.three,
.four,
.five,
.six {
  width: 56px;
  height: 56px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-weight: 300;
}

@media screen and (max-width: 640px) {
  .one,
  .two,
  .three,
  .four,
  .five,
  .six {
    width: 46px;
    height: 46px;
  }
}
</style>
