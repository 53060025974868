import { request } from "../../utils";
// import { decrypted } from "@/helpers/algo";

export const addMessage = async (payload) => {
  const res = await request.post(`/dispute/update-response`, payload);
  return res;
};

export const getDisputes = async ({ business_user_reference }) => {
  const response = await request.post(`/dispute/user-messages`, {
    business_user_reference,
  });
  return response;
};
