<template>
  <div class="bg h-screen flex flex-wrap items-center">
    <div class="w-full mx-4 md:mx-auto mb-4 py-8">
      <div class="flex items-center justify-center mb-4">
        <img
          alt="Vue logo"
          src="https://res.cloudinary.com/kingstech/image/upload/v1672278941/kredi_logo_cobxpf.png"
          class="w-32 object-cover"
        />
      </div>
      <div
        class="bg-white shadow-md rounded-md px-8 py-16 md:w-1/2"
        style="margin: 0px auto"
      >
        <div class="flex gap-3">
          <div class="mb-4">
            <label
              for=""
              class="text-xs mb-2 uppercase text-gray-400 font-medium"
              >Firstname</label
            >
            <input
              type="text"
              class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
              placeholder="Enter first name"
              v-model="form.first_name"
            />
          </div>
          <div class="mb-4">
            <label
              for=""
              class="text-xs mb-2 uppercase text-gray-400 font-medium"
              >Middle Name</label
            >
            <input
              type="text"
              class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
              placeholder="Enter middle name"
              v-model="form.middle_name"
            />
          </div>
        </div>

        <div class="mb-4">
          <label for="" class="text-xs mb-2 uppercase text-gray-400 font-medium"
            >Last Name</label
          >
          <input
            type="text"
            class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
            placeholder="Enter Last Name"
            v-model="form.last_name"
          />
        </div>
        <div class="mb-4">
          <label for="" class="text-xs mb-2 uppercase text-gray-400 font-medium"
            >Email</label
          >
          <input
            type="email"
            class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
            placeholder="Enter Email"
            v-model="form.email"
          />
        </div>
        <div class="mb-4">
          <label for="" class="text-xs mb-2 uppercase text-gray-400 font-medium"
            >Phone Number</label
          >
          <input
            type="text"
            class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
            placeholder="Enter Phone Number"
            v-model="form.phone"
          />
        </div>
        <div class="mb-4">
          <label for="" class="text-xs mb-2 uppercase text-gray-400 font-medium"
            >Password</label
          >
          <input
            type="password"
            class="w-full py-3 px-4 border border-gray-100 text-xs rounded-sm"
            placeholder="*******"
            v-model="form.password"
          />
        </div>

        <button
          :disabled="validateInput"
          class="bg-orange-600 py-4 w-full block text-center disabled:opacity-60 rounded-md text-white text-sm my-3"
          @click="signUp"
        >
          {{ loading ? "Please wait..." : "Register" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import Layout from "./Layout.vue";
import { registerAdmin } from "@/services";
import { defineComponent, ref, computed } from "vue";
// import { useRouter } from "vue-router";
// import { useStore } from "vuex";

export default defineComponent({
  name: "RegisterView",
  //   components: { Layout },
  setup() {
    //     const router = useRouter();
    //     const store = useStore();
    const form = ref({
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
    });

    const loading = ref(false);

    async function signUp() {
      loading.value = true;
      try {
        await registerAdmin(form.value).then((res) => {
          console.log(res);
        });
        //    this.$toast.success(res?.message);
      } catch (err) {
        console.log(err);
      }
      loading.value = false;
    }

    return {
      form,
      signUp,
      loading,
      validateInput: computed(() => !form.value.email || !form.value.password),
    };
  },
});
</script>
