const state = {
  disputes: JSON.parse(localStorage.getItem("disputes")) || null,
};

const getters = {};

const mutations = {
  SAVE_DISPUTES(state, disputes) {
    state.disputes = disputes;
    localStorage.setItem("disputes", JSON.stringify(disputes));
  },
};

const actions = {};

export default {
  namedspaced: true,
  state,
  getters,
  mutations,
  actions,
};
